// import ValidService from "../../service/ValidService";

import LanguageService from "../../service/LanguageService";

let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined,
  name: undefined,
  type: undefined,
  db_layer_type: undefined,
  split_type: undefined,
  service_id: undefined,
};

model.dbType = [
  {
    get label() {
      return LanguageService?.lang?.t_tong_hop ?? "t_tong_hop"
    },
    value: "tong_hop",
  },
  {
    get label() {
      return LanguageService?.lang?.t_chi_tiet ?? "t_chi_tiet"
    },
    value: 'chi_tiet',
  },
];

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "service_name",
  defaultSort: ["service_name", "ascending"],
  fields: "id,name,split_type,service_id",
  filters: "",
  dataSearch: {
    value: {
      name: "",
      split_type: "",
    },
    // valid: {
    //     // name: [ValidService.checkNameAllowVN],
    //     code: [ValidService.checkNumber],
    //     type: [ValidService.checkNameAllowVN],
    // },
    operator: {
      name: ":regex_i:",
      split_type: ":regex_i:",
      // type: ":regex_i:",
    },
  },
};

export default model;
