<script setup>
// layout
import Layout from "../../layouts/main.vue";

// import thư viện
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
// data khai báo
import MethodService from "../../service/MethodService";
import Data from "./dataFormTable";
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const loadingBtn = ref(false);
const validateFlag = ref(false);
const dataService = reactive({ value: [] });
const dataServiceClone = ref([]);
// pinia store
import { onMounted, reactive } from "vue";

const router = useRoute();
const routerPush = useRouter();
const listSplit = ["none", "year", "month", "day"];
const typeProbeBras = ["probe", "bras"];
const listDBType = ref(MethodService.copyObject(Data.dbType));
// validate form
import Validate from "../../service/ValidService";
import LanguageService from "../../service/LanguageService";

const rulesForm = reactive({
  name: [Validate.checkSpecial, Validate.required],
  split_type: [Validate.required],
  db_layer_type: [Validate.required],
  type: [Validate.required],
});

const getTableConfig = async () => {
  if (router.query.id) {
    try {
      const response = await mushroom.table_config.findByIdAsync({
        id: router.query.id,
        fields: "id,type,db_layer_type,name,split_type,service_id",
      });
      if (response.result) dataForm.value = response.result;
    } catch (e) {
      MethodService.showError(e.code);
    }
  }
};

const getService = async () => {
  try {
    const response = await mushroom.service.getAllAsync(
      { fields: "id,type,name" },
      {
        enabledCache: false,
      }
    );
    if (response.result) {
      dataService.value = response.result;
      dataServiceClone.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const filterType = (type) => {
  dataForm.value.service_id = "";
  dataService.value = dataServiceClone.value.filter(
    (item) => item.type === type
  );
};
const addNewTable = async (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const table_config_object = {
        name: dataForm.value.name,
        db_layer_type: dataForm.value.db_layer_type,
        type: dataForm.value.type,
        split_type: dataForm.value.split_type,
        service_id: dataForm.value.service_id,
      };
      try {
        const newId = await mushroom.table_config.createAsync(
          table_config_object
        );
        if (newId) {
          toastr.success(t("toastr_add_success"));
        }
        routerPush.go(-1);
      } catch (error) {
        if (error?.meta?.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else MethodService.showError(error.code);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

const updateTable = async (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const table_config_object = {
        id: router.query.id,
        name: dataForm.value.name,
        db_layer_type: dataForm.value.db_layer_type,
        type: dataForm.value.type,
        split_type: dataForm.value.split_type,
        service_id: dataForm.value.service_id,
      };
      try {
        const response = await mushroom.table_config.partialUpdateAsync(
          table_config_object
        );
        if (response.result == router.query.id) {
          toastr.success(t("toastr_update_success"));
        } else toastr.warning(t("toastr_not_change_warning"));
        routerPush.go(-1);
      } catch (error) {
        if (error?.meta?.field === "$unique_key") {
          toastr.error(t("toastr_had_name"));
        } else MethodService.showError(error.code);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    listDBType.value = MethodService.copyObject(Data).dbType;
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  await getService();
  if (router.name === "UpdateTable") {
    await getTableConfig();
    dataService.value = dataServiceClone.value.filter(
      (item) => item.type === dataForm.value.type
    );
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'CreateTable'">
          {{ $t("t-add-table") }}
        </h3>
        <h3 v-else>{{ $t("t-update-table") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item :label="$t('t-table-name')" prop="name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.name"
                @change="dataForm.value.name = dataForm.value.name.trim()"
                :placeholder="$t('t-place-table-name')"
              />
            </el-form-item>
            <el-form-item :label="$t('t-db-layer-type')" prop="db_layer_type">
              <el-select
                v-model="dataForm.value.db_layer_type"
                clearable
                filterable
                :placeholder="$t('t-place-db-layer-type')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                style="width: 100%"
                :disabled="router.name === 'UpdateTable'"
              >
                <el-option
                  v-for="item in listDBType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t-type-probe-bras')" prop="type">
              <el-select
                v-model="dataForm.value.type"
                clearable
                filterable
                :placeholder="$t('t-place-type-probe-bras')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                style="width: 100%"
                :disabled="router.name === 'UpdateTable'"
                @change="filterType"
              >
                <el-option
                  v-for="item in typeProbeBras"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('t-split-type')" prop="split_type">
              <el-select
                v-model="dataForm.value.split_type"
                clearable
                filterable
                :placeholder="$t('t-choose-split-type')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in listSplit"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('t-service')"
              prop="service_id"
              v-if="dataForm.value.type"
            >
              <el-select
                filterable
                v-model="dataForm.value.service_id"
                clearable
                :placeholder="$t('t-select-service')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dataService.value"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewTable(ruleFormRef)"
                v-if="router.name === 'CreateTable'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateTable(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.el-radio {
  margin-bottom: 0;
}
</style>
